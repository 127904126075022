<template>
  <div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import Amplitude from "@/helpers/amplitude";
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import {getQueryData} from "@/helpers/query";

const route = useRoute();
const router = useRouter();
const i18n = useI18n();
const { localeCodes } = useI18n();
const i18nHead = useLocaleHead({
  addSeoAttributes: {},
});
useHead({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  title: route.meta.title,
  meta: [
    ...(i18nHead.value.meta || []),
    { property: 'og:title', content: route.meta.title },
    { property: 'og:description', content: route.meta.description }
  ]
});
//methods
onMounted(() => {
  Amplitude.init();

  if (route.path !== "/") {
    const lang = route.query.lang;
    if (lang && localeCodes.value && localeCodes.value.includes(String(lang))) {
      i18n.setLocale(String(lang));
    }
  }

  const params = new URLSearchParams(window?.location?.search || "");
  const hash = params.get("hash");
  if (hash) {
    const validHash = hash.replace(/[^a-z0-9]/gi, "");
    window.localStorage.setItem("hash", validHash);
  }
  getQueryData(route, router);
  Amplitude.setUserPropertyFromQuery(route);
});
// import amplitude from 'amplitude-js'
// export default {
//   name: "PrivateLayout",
//   beforeMount() {
// document.querySelectorAll('.open-calendly').forEach((item) => {
//   item.addEventListener('click', () => {
//     window.Calendly.initPopupWidget({
//       url: 'https://calendly.com/paystoretransfer/30min',
//     })
//     return false
//   })
// })
// }
// mounted() {
//   const script = document.createElement('script')
//   script.type = 'text/javascript'
//   script.async = true
//   script.src = 'https://assets.calendly.com/assets/external/widget.js'
//   document.body.appendChild(script)
//
//   if (this.$config.AMPLITUDE_API_KEY) {
//     amplitude.getInstance().init(this.$config.AMPLITUDE_API_KEY, null, {
//       saveEvents: true,
//       includeUtm: true,
//       includeReferrer: true,
//       apiEndpoint: 'api-a.pst.net/api',
//     })
//     amplitude.getInstance().logEvent('home page viewed', {
//       'product name': location?.hostname || 'pst.net',
//     })
//
//     const params = new URLSearchParams(window?.location?.search || '')
//     const hash = params.get('hash')
//     if (hash) {
//       window.localStorage.setItem('hash', hash)
//     }
//     const utmSource = params.get('utm_source')
//
//     if (hash) {
//       amplitude.getInstance().setUserProperties({
//         source: 'referral_user',
//         referral_user_hash: hash,
//       })
//     } else if (utmSource) {
//       amplitude.getInstance().setUserProperties({
//         source: utmSource,
//       })
//     } else {
//       amplitude.getInstance().setUserProperties({
//         source: document?.referrer || '',
//       })
//     }
//     // EXPERIMENT
//     if (utmSource) {
//       if (Math.random() > 0.5) {
//         amplitude.getInstance().setUserProperties({
//           experiment: `landing_redirect_experiment`,
//         })
//         amplitude.getInstance().logEvent(
//           'home page redirected',
//           { 'product name': location?.hostname || 'pst.net' },
//           () => {
//             location.href = 'https://beta.pst.net/register'
//           },
//           () => {
//             location.href = 'https://beta.pst.net/register'
//           }
//         )
//       }
//     }
//   }
// },
// };
</script>

<style lang="scss">
html.p-private {
  & .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 16px;

    @screen xl {
      padding: 0 32px;
    }
  }
}
</style>
